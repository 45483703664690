.moderator-hallmarks-table {
  width: calc(100% + 48px);
  border-collapse: collapse;
  margin-left: -24px;

  th {
    font-size: 14px;
    font-weight: $fontWeightNormal;
  }

  th,
  td {
    max-width: 180px;
    padding: 12px 6px;
  }

  th:first-child,
  td:first-child {
    padding-left: 24px;
  }

  th:last-child,
  td:last-child {
    padding-right: 24px;
  }

  td:nth-child(3),
  td:nth-child(4) {
    min-width: 110px;
  }

  tr {
    border-bottom: 1px solid $greyColorDark;
  }

  .buttons {
    display: flex;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      color: $textColorDark;
    }

    button:hover {
      color: $accentColor;
    }

    button + button {
      margin-left: 4px;
    }

    button:disabled {
      color: $accentColorDisabled;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
