.achivements__title {
  font-weight: $fontWeightBold;
  margin: 24px 0;
}

.achievements__subtitle {
  margin-top: -12px;
  margin-bottom: 24px;
}

.achievements__subtitle a {
  color: $accentColor;
}

.achievements__subtitle a:hover {
  color: $accentColorHover;
}

.achivements__button {
  margin-bottom: 30px;
}
