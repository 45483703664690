.toast {
  display: flex;
  width: 830px;
  border-radius: $borderRadiusLg;
  font-weight: $fontWeightBold;
  background-color: $greyColor;
  color: white;
  overflow: hidden;
}

.toast--theme-success {
  background-color: $successColor;
  color: white;
}

.toast--theme-danger {
  background-color: $dangerColor;
}

.toast__body {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px 25px;
}

.toast__icon {
  margin-right: 10px;
}

.toast__button {
  padding: 20px 25px;
  font-size: 20px;
}

.toast__button--theme-danger,
.toast__button--theme-success {
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 900px) {
  .toast {
    max-width: 100%;
    width: auto;
  }
}
