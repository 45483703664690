.students-page {
  padding: 64px 40px;

  &__box {
    margin: 16px 0;
  }

  &__link {
    color: blue;
    text-decoration: underline;
  }
}
